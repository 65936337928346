import React, { useEffect, useReducer, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Button,
    Grid,
} from "@material-ui/core";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { LaunchOutlined } from "@material-ui/icons";
import openSocket from "../../services/socket-io";


const reducer = (state, action) => {
    if (action.type === "LOAD_AGENDAS") {
        const agendas = action.payload;
        const newAgendas = [];

        agendas.forEach((agenda) => {
            const agendaIndex = state.findIndex((a) => a.id === agenda.id);
            if (agendaIndex !== -1) {
                state[agendaIndex] = agenda;
            } else {
                newAgendas.push(agenda);
            }
        });

        return [...state, ...newAgendas];
    }

    if (action.type === "UPDATE_AGENDAS") {
        const agenda = action.payload;
        const agendaIndex = state.findIndex((a) => a.id === agenda.id);

        if (agendaIndex !== -1) {
            state[agendaIndex] = agenda;
            return [...state];
        } else {
            return [...state, agenda];
        }
    }
};

const AgendasList = () => {
    const [agendas, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(true);

    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const { data } = await api.get("/agendas");
                dispatch({ type: "LOAD_AGENDAS", payload: data });
                setLoading(false);
                console.log(data);
            } catch (error) {
                console.log(error);
                toastError(error);
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        const socket = openSocket();

        socket.on("agenda", (data) => {

            if (data.action === "create" || data.action === "update") {
                dispatch({ type: "UPDATE_AGENDAS", payload: data.agenda });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);


    const handleSelectAgenda = async (id) => {
        history.push(`/agendas/${id}`);
    };

    return (
        <div>
            {agendas.map((agenda) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSelectAgenda(agenda.id)}
                    style={{ flex: 1, margin: 3 }}
                >
                    {`Agenda ${agenda.name}`}
                    <LaunchOutlined style={{ marginLeft: "8px" }} />
                </Button>
            ))}
        </div>
    );
};

export default AgendasList;