import React, { useReducer, useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Chip,
    Box,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Select,
    Switch,
    FormControlLabel,
    ClickAwayListener,
    Paper,
    Tooltip,
    Collapse,
    Grid,
    Typography,
    Divider,
    Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import toastError from "../../errors/toastError";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Stack } from "@mui/material";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from "react-toastify";
import IntervalsDateList from "../IntervalsDateList";
import { ReactComponent as ChevronDown } from 'bootstrap-icons/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'bootstrap-icons/icons/chevron-up.svg';






const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: "wrap",
        padding: theme.spacing(2),
    },
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_CONTACTS") {
        const contacts = action.payload;
        const newContacts = [];

        contacts.forEach((contact) => {
            const contactIndex = state.findIndex((c) => c.id === contact.id);
            if (contactIndex !== -1) {
                state[contactIndex] = contact;
            } else {
                newContacts.push(contact);
            }
        });
        return [...state, ...newContacts];
    }

    if (action.type === "UPDATE_CONTACTS") {
        const contact = action.payload;
        const contactIndex = state.findIndex((c) => c.id === contact.id);

        if (contactIndex !== -1) {
            state[contactIndex] = contact;
            return [...state];
        } else {
            return [contact, ...state];
        }
    }

    if (action.type === "DELETE_CONTACT") {
        const contactId = action.payload;

        const contactIndex = state.findIndex((c) => c.id === contactId);
        if (contactIndex !== -1) {
            state.splice(contactIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const daysOfWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

const EventsModal = ({ open, onClose, slotInfo, agendaId, contactId }) => {
    const classes = useStyles();

    const [contacts, dispatch] = useReducer(reducer, []);
    const [calendars, setCalendars] = useState([]);
    const [routines, setRoutines] = useState([]);

    const [title, setTitle] = useState("");
    const [start, setStart] = useState(null);
    const [allDay, setAllDay] = useState(false);
    const [description, setDescription] = useState("");
    const [selectedContact, setSelectedContact] = useState(null);
    const [selectedCalendarId, setSelectedCalendarId] = useState(null);
    const [selectedRoutines, setSelectedRoutines] = useState(null);

    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    const [date, setDate] = useState("");
    const [duration, setDuration] = useState(null);

    const [intervalClicked, setIntervalClicked] = useState(false);

    const [openRepeatEvent, setOpenRepeatEvent] = useState(false);
    const [repeatEvent, setRepeatEvent] = useState(false);
    const [selectedDays, setSelectedDays] = useState([]);
    const [ocurrences, setOcurrences] = useState(0);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("/contacts/list", {
                        params: { searchParam, pageNumber },
                    });
                    dispatch({ type: "LOAD_CONTACTS", payload: data });

                    if (contactId) {
                        setSelectedContact(data.find(contact => contact.id === contactId));
                    }

                } catch (err) {
                    console.log('err', err);
                    toastError(err);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber]);

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const { data } = await api.get("/agendas");
                setCalendars(data);

                console.log("slotInfo", slotInfo);

                if (slotInfo) {
                    const { start, slots } = slotInfo;
                    const newStart = new Date(start);

                    setDate(newStart.toISOString().split("T")[0]);

                    // Formatar o horário para o input de horário (HH:MM)
                    const hours = newStart.getHours().toString().padStart(2, "0");
                    const minutes = newStart.getMinutes().toString().padStart(2, "0");
                    const time = `${hours}:${minutes}`;

                    setStart(time);


                } else {
                    const newDate = new Date();
                    setDate(newDate.toISOString().split("T")[0]);

                    // Definir o horário atual formatado como "HH:MM"
                    const hours = newDate.getHours().toString().padStart(2, "0");
                    const minutes = newDate.getMinutes().toString().padStart(2, "0");
                    setStart(`${hours}:${minutes}`);

                }

                console.log("data e hora", date, start);

                console.log("agendaId", agendaId);
                if (agendaId) {
                    setSelectedCalendarId(data.find(calendar => calendar.id === agendaId));
                }


            } catch (error) {
                console.log(error);
                toastError(error);
            }
            setLoading(false);
        })();
        fetchRoutines();
    }, []);

    const fetchRoutines = async () => {
        try {
            const { data } = await api.get("/agendas/routine");
            setRoutines(data);
            console.log(data);
        } catch (error) {
            console.log(error);
            toastError(error);
        }
    };

    const renderOptionLabel = option => {
        if (!option) return "Escolha o contato";
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${option.name}`;
        }
    };

    const renderAgendaLabel = (option) => {
        // Verifique se o valor de option não está indefinido ou nulo
        if (!option || typeof option === 'string') {
            return option || '';  // Retorna uma string vazia caso não haja opção
        }
        // Verifique se a agenda tem o nome definido
        return option.title ? `${option.title}` : 'Sem nome';
    };

    const handleSelectOption = (e, newValue) => {
        setSelectedContact(newValue);
    };

    const handleSelectAgenda = (e, newValue) => {
        setSelectedCalendarId(newValue);
    };

    const handleRoutineChange = (e, newValue) => {
        setSelectedRoutines(newValue);
    };

    const handleRemoveRoutine = (id) => {
        setSelectedRoutines(selectedRoutines.filter(routine => routine.id !== id));
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        console.log('scrolling');
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const verifyData = (date, agendaId, start) => {

        const agenda = calendars.find(calendar => calendar.id === agendaId);
        console.log("agenda", agenda);

        if (agenda) {
            const {
                sunday,
                monday,
                tuesday,
                wednesday,
                thursday,
                friday,
                saturday
            } = agenda;

            const day = new Date(`${date}T${start}`).getDay();

            console.log("day", day);

            switch (day) {
                case 0:
                    return sunday;
                case 1:
                    return monday;
                case 2:
                    return tuesday;
                case 3:
                    return wednesday;
                case 4:
                    return thursday;
                case 5:
                    return friday;
                case 6:
                    return saturday;
                default:
                    return false;
            }
        } else {
            return false;    //agenda não encontrada      
        }
    };

    const handleSubmit = async (e) => {

        if (repeatEvent && selectedDays.length === 0 || repeatEvent && ocurrences <= 0) {
            toast.error("Selecione os dias da semana e o número de ocorrências para o agendamento recorrente");
            return;
        }


        if (!allDay) {
            if (!title || !date || !start || !duration) {
                toast.error("Preencha todos os campos obrigatórios");
                return;
            };
        }

        const contactId = selectedContact ? selectedContact.id : null;
        const agendaId = selectedCalendarId ? selectedCalendarId.id : null;
        const routineId = selectedRoutines ? selectedRoutines.id : null;

        const blocked = verifyData(date, agendaId, start);

        if (!blocked) {
            toast.error("Este dia da semana não está disponível na agenda selecionada");
            return;
        }

        //preciso separar a data e uma sting A, M, D, H, M
        // const date = new Date();
        // const dateStr = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
        console.log("selectedDays", selectedDays);

        const eventData = {
            title,
            date,
            start: allDay ? "00:00" : start,
            allDay,
            duration,
            description,
            contactId,
            agendaId,
            routineId,
            repeatEvent,
            selectedDays,
            ocurrences,
            blocked: false,
        };

        console.log("event ", eventData);

        try {
            await api.post("/events", eventData);
            toast.success("Evento criado com sucesso!");
        } catch (error) {
            console.error("Erro ao criar evento:", error);
            toastError(error);
        }
        handleClose();
    };

    const style = () => {
        if (!agendaId) {
            return { width: "50%" };
        } else {
            return { width: "100%" };
        }
    }

    const handleClose = () => {
        onClose();
        setTitle("");
        setStart(null);
        setAllDay(false);
        setDescription("");
        setDate("");
        setDuration(null);
        setSelectedContact(null);
        setSelectedCalendarId(null);
        setSelectedRoutines(null);
        setRepeatEvent(false);
        setSelectedDays([]);
        setOcurrences(0);
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title">
                    {!agendaId ? `Criar Agendamento` : `Criar Agendamento na Agenda: ${selectedCalendarId?.name}`}
                </DialogTitle>
                {!loading && (
                    <DialogContent dividers>
                        <TextField
                            fullWidth
                            label="Evento"
                            margin="dense"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}

                        />

                        <Stack spacing={2} direction={'row'} alignItems={'center'}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                maxWidth: "100vw",
                                width: "100%",
                                marginTop: 2,
                            }}
                        >
                            <Autocomplete
                                options={contacts}
                                limitTags={1}
                                style={style()}
                                value={selectedContact}
                                id="multiple-limit-tags"
                                getOptionLabel={renderOptionLabel}
                                onChange={(e, newValue) => handleSelectOption(e, newValue)}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.name}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField {...params}
                                        label={i18n.t("newTicketModal.fieldLabel")}
                                        variant="outlined"
                                        sx={{ width: '100%' }}
                                        placeholder="Escolha o contato"
                                        onScroll={handleScroll}

                                    />
                                )}
                            />
                            {!agendaId ? (
                                <Autocomplete
                                    options={calendars}
                                    id="multiple-limit-tags"
                                    style={{ width: "50%" }}
                                    limitTags={1}
                                    getOptionLabel={(option) => option.name}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip
                                                label={option.name}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            label="Escolha uma Agenda"
                                            variant="outlined"
                                            sx={{ width: '100%' }}
                                        />
                                    }
                                    value={selectedCalendarId}
                                    onChange={(e, newValue) =>
                                        handleSelectAgenda(e, newValue)
                                    }
                                />) : null}

                        </Stack>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={allDay}
                                    onChange={(e) => setAllDay(e.target.checked)}
                                    name="allDay"
                                    color="primary"
                                />
                            }
                            label="Dia inteiro"
                        />
                        <Stack spacing={1} direction={'row'}
                            sx={{
                                my: 2,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                maxWidth: "100vw",
                                width: "100%",
                            }}>
                            <Box sx={{ display: "flex", flex: 1, width: "100%" }}>
                                <TextField
                                    label="Data"
                                    type="date"
                                    variant="outlined"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                    sx={{ width: "100%" }}
                                />
                                {console.log("date", date)}
                            </Box>
                            <Box sx={{ display: "flex", flex: 1, width: "100%" }}>
                                <TextField
                                    label="Horário"
                                    type="time"
                                    variant="outlined"
                                    value={start}
                                    onChange={(e) => setStart(e.target.value)}
                                    disabled={allDay}
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                            <Box sx={{ display: "flex", width: "100%" }}>
                                <FormControlLabel
                                    control={
                                        <Select
                                            label="Duração"
                                            value={duration}
                                            onChange={(e) => setDuration(e.target.value)}
                                            sx={{ width: "100%", fontSize: "0.9rem" }}
                                            disabled={allDay}
                                        >
                                            <option value={1}>1 min</option>
                                            <option value={5}>5 min</option>
                                            <option value={10}>10 min</option>
                                            <option value={15}>15 min</option>
                                            <option value={20}>20 min</option>
                                            <option value={25}>25 min</option>
                                            <option value={30}>30 min</option>
                                            <option value={35}>35 min</option>
                                            <option value={40}>40 min</option>
                                            <option value={45}>45 min</option>
                                            <option value={50}>50 min</option>
                                            <option value={60}>1 h</option>
                                            <option value={90}>1 h e 30 min</option>
                                            <option value={120}>2 h</option>
                                        </Select>
                                    }
                                    label="Duração"
                                    labelPlacement="top"
                                    sx={{ fontSize: "0.9rem" }}
                                />
                            </Box>

                            <ClickAwayListener onClickAway={() => setIntervalClicked(false)}>
                                <div style={{ position: "relative" }}>
                                    <Tooltip title="Selecione a agenda, data e clique para ver os horários disponíveis" arrow>
                                        <Button
                                            onClick={() => setIntervalClicked((prev) => !prev)}
                                            color="primary"
                                            size="small"
                                            disabled={!selectedCalendarId || !date}
                                        >
                                            Horários Disponíveis
                                        </Button>
                                    </Tooltip>
                                    {intervalClicked ? (
                                        <Paper style={{
                                            position: "absolute",
                                            zIndex: 1,
                                            backgroundColor: "#ededed",
                                            border: "1px solid #ccc",
                                            borderRadius: "5px",
                                            padding: "5px",
                                            width: "140px",
                                            maxHeight: "180px",
                                            overflowY: "auto",
                                            top: "100%",
                                            right: "0",
                                            boxShadow: "0 0 5px rgba(0,0,0,0.5)"
                                        }}>
                                            <IntervalsDateList
                                                date={date}
                                                agendaId={selectedCalendarId.id}
                                                open={intervalClicked}
                                            />

                                        </Paper>) : null}
                                </div>
                            </ClickAwayListener>

                        </Stack>
                        <div>
                            <TextField
                                label="Descrição"
                                variant="outlined"
                                fullWidth
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                multiline
                                rows={2}
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            {/*
                            <List>
                                {selectedRoutines.map(routine => (
                                    <ListItem
                                        key={routine.id}
                                        secondaryAction={
                                            <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveRoutine(routine.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemText
                                            primary={routine.title}
                                            secondary={`será enviada ${routine.timeBefore} ${routine.timeUnit}`}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                            */}
                            <Autocomplete
                                style={{ marginTop: 3 }}
                                options={routines} // Lista de rotinas disponível
                                filterSelectedOptions // Remove rotinas já selecionadas da lista
                                getOptionLabel={renderAgendaLabel}
                                value={selectedRoutines} // Rotinas atualmente selecionadas
                                onChange={handleRoutineChange}
                                renderTags={(selected, getTagProps) =>
                                    selected.map((option, index) => (
                                        <Chip
                                            variant="outlined"
                                            key={option.id} // Identificador único da rotina
                                            label={option.title} // Exibe o nome da rotina
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Selecione a Rotina"
                                        placeholder="Escolher rotina"
                                    />
                                )}
                            />
                        </div>

                        <div style={{ marginTop: 6 }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setOpenRepeatEvent((prev) => !prev)}
                            >
                                Agendamento Recorrente
                                {openRepeatEvent
                                    ? <ChevronUp style={{ marginLeft: "5px" }} />
                                    : <ChevronDown style={{ marginLeft: "5px" }} />}
                            </Button>
                        </div>
                        <div style={{ marginTop: 6 }}>
                            <Collapse in={openRepeatEvent}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={repeatEvent}
                                            onChange={(e) => setRepeatEvent(e.target.checked)}
                                            name="repeatEvent"
                                            color="primary"
                                        />
                                    }
                                    label="Ativar agendamento recorrente"
                                />
                                <Divider />
                                <Grid container spacing={2}>
                                    {daysOfWeek.map((day, index) => (
                                        <Grid item xs={6} key={index}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={selectedDays.includes(index)}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setSelectedDays((prev) => [...prev, index]);
                                                                console.log("selectedDays", selectedDays);
                                                            } else {
                                                                setSelectedDays((prev) => prev.filter((item) => item !== index));
                                                            }
                                                        }}
                                                        name={day}
                                                        color="primary"
                                                        disabled={!repeatEvent}
                                                    />
                                                }
                                                label={day}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                <div style={{ marginTop: 10 }}>
                                    <Typography variant="caption" style={{ fontSize: "1rem" }}>
                                        Termina após{" "}
                                        <TextField
                                            type="number"
                                            variant="outlined"
                                            value={ocurrences}
                                            onChange={(e) => setOcurrences(e.target.value)}
                                            disabled={!repeatEvent}
                                            inputProps={{ style: { textAlign: 'center', padding: '0' } }} // Centraliza o texto no input e remove o padding interno
                                            style={{ width: "5ch", marginLeft: "5px", marginRight: "5px", marginBottom: "-6px" }}
                                        />
                                        {" "}ocorrências
                                    </Typography>
                                </div>
                            </Collapse>
                        </div>

                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Salvar
                    </Button>
                </DialogActions>

            </Dialog>
        </div>
    );
};

export default EventsModal;