import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    IconButton,
    RadioGroup,
    Radio,
    FormControlLabel,
    Typography,
    Divider,
    Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { set } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    addButton: {
        marginTop: theme.spacing(2),
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row', // Deixa os rádios em linha
    },
    btnContainer: {
        textAlign: "right",

    },
}));

const RoutineSchema = Yup.object().shape({
    messages: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('Título é obrigatório'),
            message: Yup.string().required('Mensagem é obrigatória'),
            timeBefore: Yup.string().required('Tempo é obrigatório'),
        })
    ),
});

const AgendaRoutineEditModal = ({ open, onClose, routineId }) => {
    const classes = useStyles();

    const initialValues = {
        title: '',
        message: '',
        timeBefore: '',
        timeUnit: '',
        confirmation: '',
    };

    const [timeUnitCurrent, setTimeUnitCurrent] = useState(null);
    const [confirmationCurrent, setConfirmationCurrent] = useState(null);

    const [routine, setRoutine] = useState(initialValues);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        if (routineId) {
            (async () => {
                try {
                    const { data } = await api.get(`/agendas/routine/${routineId}`);
                    console.log(data);
                    setRoutine(prevState => {
                        return { ...prevState, ...data };
                    });
                    setTimeUnitCurrent(data.timeUnit);
                    setConfirmationCurrent(data.confirmation);
                } catch (error) {
                    console.log(error);
                    toast.error('Erro ao buscar rotina!');
                }
            })();
            setLoading(false);
        }
    }, [open, routineId]);

    const handleSubmit = async (values) => {

        console.log(values);

        const { title, message, timeBefore, timeUnit, confirmation } = values;

        console.log(title, message, timeBefore, timeUnit, confirmation);

        try {
            if (routineId) {

                const response = await api.put(`/agendas/routine/${routineId}`, {
                    title,
                    message,
                    timeBefore,
                    timeUnit,
                    confirmation,
                });
                console.log(response.data);

                toast.success('Rotina atualizada com sucesso!');

            }
        } catch (error) {
            console.log(error);
            toast.error('Erro ao cadastrar rotina!');
        }

        handleClose();
    };

    const handleClose = () => {
        onClose();
        setRoutine(initialValues);
    };



    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
            <DialogTitle id="form-dialog-title">
                Editar Rotina
            </DialogTitle>
            {!loading && (
                <DialogContent dividers>
                    <Formik
                        initialValues={routine}
                        enableReinitialize={true}
                        validationSchema={RoutineSchema}
                        onSubmit={(values, actions) => {
                            setTimeout(() => {
                                handleSubmit(values);
                                actions.setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({ touched, errors, isSubmitting, setFieldValue }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field
                                            name="title"
                                            as={TextField}
                                            label="Título"
                                            fullWidth
                                            margin="dense"
                                            variant="outlined"
                                            error={touched.title && Boolean(errors.title)}
                                            helperText={touched.title && errors.title}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="message"
                                            as={TextField}
                                            label="Mensagem"
                                            fullWidth
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                            margin="dense"
                                            error={touched.message && Boolean(errors.message)}
                                            helperText={touched.message && errors.message}
                                        />
                                    </Grid>
                                    <Grid item container xs={7} direction="row">

                                        <Grid item>
                                            <Field
                                                name="timeBefore"
                                                as={TextField}
                                                label="Tempo para o envio"
                                                fullWidth
                                                variant="outlined"
                                                margin="dense"
                                                error={touched.timeBefore && Boolean(errors.timeBefore)}
                                                helperText={touched.timeBefore && errors.timeBefore}
                                            />
                                        </Grid>
                                        <Grid item style={{ marginLeft: 10 }}>
                                            <RadioGroup
                                                name="timeUnit"
                                                className={classes.radioGroup}
                                                value={timeUnitCurrent}
                                                onChange={(e) => {
                                                    setFieldValue('timeUnit', e.target.value);
                                                    setTimeUnitCurrent(e.target.value);
                                                }}
                                                
                                            >
                                                <FormControlLabel
                                                    value="minutos"
                                                    control={<Radio />}
                                                    label="minutos"
                                                />
                                                <FormControlLabel
                                                    value="horas"
                                                    control={<Radio />}
                                                    label="horas"
                                                />
                                                <FormControlLabel
                                                    value="dias"
                                                    control={<Radio />}
                                                    label="dias"
                                                />
                                            </RadioGroup>
                                            {touched?.timeUnit && errors?.timeUnit && (
                                                <div style={{ color: 'red' }}>{errors.timeUnit}</div>
                                            )}
                                        </Grid>

                                    </Grid>
                                    <Grid item container xs={12} direction="row">
                                        <Grid item xs={5}>
                                            <Typography
                                                variant="body2"
                                                style={{ fontSize: "1rem" }}
                                            >Precisa de confirmação?
                                            </Typography>
                                            <Field
                                                name="confirmation"
                                                as={Select}
                                                label="Precisa de confirmação"
                                                size="small"
                                                variant="outlined"
                                                margin="dense"
                                                error={touched.confirmation && Boolean(errors.confirmation)}
                                                helperText={touched.confirmation && errors.confirmation}
                                                onChange={(e) => {
                                                    setFieldValue('confirmation', e.target.value);
                                                }}

                                            >
                                                <option
                                                    value="true"
                                                    selected={confirmationCurrent === true}
                                                >Sim
                                                </option>
                                                <option
                                                    value="false"
                                                    selected={confirmationCurrent === false}
                                                >Não
                                                </option>
                                            </Field>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Divider style={{ marginTop: "10px" }} />

                                <DialogActions>
                                    <Button
                                        onClick={handleClose}
                                        color="secondary"
                                        disabled={isSubmitting}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={isSubmitting}
                                    >
                                        Atualizar
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default AgendaRoutineEditModal;