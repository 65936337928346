import React, { useEffect, useState } from "react";
import { set, toString } from "lodash";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { TableCell, Table, TableRow } from "@material-ui/core";


const IntervalsDateList = ({ date, agendaId, open }) => {
    const [intervalsData, setIntervalsData] = useState([]);
    const [newLoading, setNewLoading] = useState(false);
    const [allDay, setAllDay] = useState(false);

    useEffect(() => {
        if (!date) return;
        if (!agendaId) return;

        setNewLoading(true);

        console.log("date", date);
        console.log("agendaId", agendaId);

        const fetchIntervals = async () => {
            try {
                const { data } = await api.get("/events", { params: { agendaId } })
                const calendar = await api.get(`/agendas/${agendaId}`);

                const agenda = calendar.data;

                const events = data.filter(event => event.date === date);

                const orderedEvents = events.sort((a, b) => {
                    if (a.start < b.start) {
                        return -1;
                    }
                    if (a.start > b.start) {
                        return 1;
                    }
                    return 0;
                }); // Ordena em ordem crescente

                const eventsBlocked = orderedEvents.filter(event => event.blocked);

                console.log(events);
                console.log(data);
                console.log(agenda);

                let intervals = [];


                if (events.length === 0 || agenda.multipleEvents) {
                    if (eventsBlocked.length > 0) {

                        const HHstart = toString(eventsBlocked[0].end).split(":")[0];
                        const MMstart = parseInt(toString(eventsBlocked[0].end).split(":")[1]) - 1;

                        const startInMinutes = parseInt(HHstart) * 60 + MMstart;
                        const newStartHH = Math.floor((startInMinutes) / 60);
                        const newStartMM = (startInMinutes) % 60 < 10 ? `0${(startInMinutes) % 60}` : (startInMinutes) % 60;
                        const start = newStartHH < 10 ? `0${newStartHH}:${newStartMM}` : `${newStartHH}:${newStartMM}`;

                        intervals = [{ intervalStart: agenda.startTime, intervalEnd: start }];

                        if (eventsBlocked.length > 1) {
                            for (let i = 0; i < eventsBlocked.length - 1; i++) {
                                const HHstart = toString(eventsBlocked[i].end).split(":")[0];
                                const MMstart = parseInt(toString(eventsBlocked[i].end).split(":")[1]) + 1;
                                const HHend = toString(eventsBlocked[i + 1].start).split(":")[0];
                                const MMend = parseInt(toString(eventsBlocked[i + 1].start).split(":")[1]) - 1;

                                const startInMinutes = parseInt(HHstart) * 60 + MMstart;
                                const endInMinutes = parseInt(HHend) * 60 + MMend;
                                const newStartHH = Math.floor((startInMinutes) / 60);
                                const newStartMM = (startInMinutes) % 60 < 10 ? `0${(startInMinutes) % 60}` : (startInMinutes) % 60;
                                const newEndHH = Math.floor((endInMinutes) / 60);
                                const newEndMM = (endInMinutes) % 60 < 10 ? `0${(endInMinutes) % 60}` : (endInMinutes) % 60;

                                const start = newStartHH < 10 ? `0${newStartHH}:${newStartMM}` : `${newStartHH}:${newStartMM}`;
                                const end = newEndHH < 10 ? `0${newEndHH}:${newEndMM}` : `${newEndHH}:${newEndMM}`;

                                intervals.push({ intervalStart: start, intervalEnd: end });
                            }
                        }

                        const HHend = toString(eventsBlocked[eventsBlocked.length - 1].end).split(":")[0];
                        const MMend = parseInt(toString(eventsBlocked[eventsBlocked.length - 1].end).split(":")[1]) + 1;
                        const endInMinutes = parseInt(HHend) * 60 + MMend;
                        const newEndHH = Math.floor((endInMinutes) / 60);
                        const newEndMM = (endInMinutes) % 60 < 10 ? `0${(endInMinutes) % 60}` : (endInMinutes) % 60;
                        const end = newEndHH < 10 ? `0${newEndHH}:${newEndMM}` : `${newEndHH}:${newEndMM}`;

                        intervals.push({ intervalStart: end, intervalEnd: agenda.endTime });

                    } else {
                        intervals = [{ intervalStart: agenda.startTime, intervalEnd: agenda.endTime }];
                    }
                } else {

                    const eventAllDay = orderedEvents.filter(event => event.allDay);

                    if (eventAllDay.length > 0) {

                        setAllDay(true);

                    } else {

                        const HHstart = toString(orderedEvents[0].start).split(":")[0];
                        const MMstart = parseInt(toString(orderedEvents[0].start).split(":")[1]) - 1;

                        const startInMinutes = parseInt(HHstart) * 60 + MMstart;
                        const newStartHH = Math.floor((startInMinutes) / 60);
                        const newStartMM = (startInMinutes) % 60 < 10 ? `0${(startInMinutes) % 60}` : (startInMinutes) % 60;
                        const start = newStartHH < 10 ? `0${newStartHH}:${newStartMM}` : `${newStartHH}:${newStartMM}`;


                        intervals = [
                            {
                                intervalStart: `${agenda.startTime}`,
                                intervalEnd: start
                            }
                        ]
                        if (events.length > 1) {
                            for (let i = 0; i < events.length - 1; i++) {
                                const HHstart = toString(orderedEvents[i].end).split(":")[0];
                                const MMstart = parseInt(toString(orderedEvents[i].end).split(":")[1]) + 1;
                                const HHend = toString(orderedEvents[i + 1].start).split(":")[0];
                                const MMend = parseInt(toString(orderedEvents[i + 1].start).split(":")[1]) - 1;

                                const startInMinutes = parseInt(HHstart) * 60 + MMstart;
                                const endInMinutes = parseInt(HHend) * 60 + MMend;
                                const newStartHH = Math.floor((startInMinutes) / 60);
                                const newStartMM = (startInMinutes) % 60 < 10 ? `0${(startInMinutes) % 60}` : (startInMinutes) % 60;
                                const newEndHH = Math.floor((endInMinutes) / 60);
                                const newEndMM = (endInMinutes) % 60 < 10 ? `0${(endInMinutes) % 60}` : (endInMinutes) % 60;

                                const start = newStartHH < 10 ? `0${newStartHH}:${newStartMM}` : `${newStartHH}:${newStartMM}`;
                                const end = newEndHH < 10 ? `0${newEndHH}:${newEndMM}` : `${newEndHH}:${newEndMM}`;


                                intervals.push({
                                    intervalStart: start,
                                    intervalEnd: end
                                });
                            }
                        }

                        const HHend = toString(orderedEvents[events.length - 1].end).split(":")[0];
                        const MMend = parseInt(toString(orderedEvents[events.length - 1].end).split(":")[1]) + 1;
                        const endInMinutes = parseInt(HHend) * 60 + MMend;
                        const newEndHH = Math.floor((endInMinutes) / 60);
                        const newEndMM = (endInMinutes) % 60 < 10 ? `0${(endInMinutes) % 60}` : (endInMinutes) % 60;
                        const end = newEndHH < 10 ? `0${newEndHH}:${newEndMM}` : `${newEndHH}:${newEndMM}`;


                        intervals.push({
                            intervalStart: end,
                            intervalEnd: `${agenda.endTime}`
                        });

                    }
                }

                setIntervalsData(intervals);
                console.log(intervals);
                setNewLoading(false);

            } catch (error) {
                console.log(error);
                setNewLoading(false);
                toastError(error);
            }
        };

        fetchIntervals();
    }, [date, agendaId, open]);


    return (
        <div>
            {newLoading && <p>Carregando...</p>}

            {!newLoading && (
                <>
                    {allDay ? (
                        <p style={{ textAlign: 'center' }}> Sem horário disponível</p>
                    ) : (
                            <Table>

                                {intervalsData.map((interval, index) => (
                                    <TableRow key={index} align="center">
                                        <TableCell align="center" colSpan={2}>
                                            {`${interval.intervalStart} - ${interval.intervalEnd}`}
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </Table>
                        )}
                </>
            )}
        </div >
    );

};

export default IntervalsDateList;
