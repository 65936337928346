import React, { useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Box,
    Select,
    Switch,
    FormControlLabel,
    ClickAwayListener,
    Paper,
    Tooltip,
    Collapse,
    Divider,
    Typography,
    Grid,
    Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import toastError from "../../errors/toastError";
import { Stack } from "@mui/material";
import api from "../../services/api";
import { toast } from "react-toastify";
import IntervalsDateList from "../IntervalsDateList";
import { ReactComponent as ChevronDown } from 'bootstrap-icons/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'bootstrap-icons/icons/chevron-up.svg';


const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: "wrap",
        padding: theme.spacing(2),
    },
}));

const daysOfWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

const BlockModal = ({ open, onClose, slotInfo, agendaId }) => {
    const classes = useStyles();

    const [calendars, setCalendars] = useState([]);

    const [title, setTitle] = useState("");
    const [start, setStart] = useState(null);
    const [allDay, setAllDay] = useState(false);
    const [selectedCalendarId, setSelectedCalendarId] = useState(null);

    const [loading, setLoading] = useState(false);

    const [date, setDate] = useState("");
    const [duration, setDuration] = useState(null);
    const [intervalClicked, setIntervalClicked] = useState(false);

    const [openRepeatEvent, setOpenRepeatEvent] = useState(false);
    const [repeatEvent, setRepeatEvent] = useState(false);
    const [selectedDays, setSelectedDays] = useState([]);
    const [ocurrences, setOcurrences] = useState(0);

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const { data } = await api.get("/agendas");
                setCalendars(data);

                console.log("slotInfo", slotInfo);

                if (slotInfo) {
                    const { start, slots } = slotInfo;
                    const newStart = new Date(start);

                    setDate(newStart.toISOString().split("T")[0]);

                    // Formatar o horário para o input de horário (HH:MM)
                    const hours = newStart.getHours().toString().padStart(2, "0");
                    const minutes = newStart.getMinutes().toString().padStart(2, "0");
                    const time = `${hours}:${minutes}`;

                    setStart(time);


                } else {
                    const newDate = new Date();
                    setDate(newDate.toISOString().split("T")[0]);

                    // Definir o horário atual formatado como "HH:MM"
                    const hours = newDate.getHours().toString().padStart(2, "0");
                    const minutes = newDate.getMinutes().toString().padStart(2, "0");
                    setStart(`${hours}:${minutes}`);

                }

                console.log("data e hora", date, start);

                console.log("agendaId", agendaId);
                if (agendaId) {
                    setSelectedCalendarId(data.find(calendar => calendar.id === agendaId));
                }


            } catch (error) {
                console.log(error);
                toastError(error);
            }
            setLoading(false);
        })();
    }, []);

    const handleSubmit = async (e) => {

        if(repeatEvent && selectedDays.length === 0 || repeatEvent && ocurrences <= 0){
            toast.error("Selecione os dias da semana e o número de ocorrências para o agendamento recorrente");
            return;
        }   

        const agendaId = selectedCalendarId ? selectedCalendarId.id : null;

        console.log("selectedDays", selectedDays);
        //preciso separar a data e uma sting A, M, D, H, M
        // const date = new Date();
        // const dateStr = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;

        const eventData = {
            title,
            date,
            start: allDay ? "00:00" : start,
            allDay,
            duration,
            agendaId,
            repeatEvent,
            selectedDays,
            ocurrences,
            blocked: true,
        };

        console.log("event ", eventData);

        try {
            await api.post("/events/blocked", eventData);
            toast.success("Bloqueio de horário criado com sucesso!");
        } catch (error) {
            console.error("Erro ao criar bloqueio de horário:", error);
            toastError(error);
        }
        handleClose();
    };

    const handleClose = () => {
        onClose();
        setTitle("");
        setStart(null);
        setAllDay(false);
        setDate("");
        setDuration(null);
        setSelectedCalendarId(null);
        setOpenRepeatEvent(false);
        setRepeatEvent(false);
        setSelectedDays([]);
        setOcurrences(0);
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title">
                    {`Bloquear horário na Agenda: ${selectedCalendarId?.name}`}
                </DialogTitle>
                {!loading && (
                    <DialogContent dividers>
                        <TextField
                            fullWidth
                            label="Título"
                            margin="dense"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}

                        />

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={allDay}
                                    onChange={(e) => setAllDay(e.target.checked)}
                                    name="allDay"
                                    color="primary"
                                />
                            }
                            label="Dia inteiro"
                        />
                        <Stack spacing={1} direction={'row'}
                            sx={{
                                my: 2,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                maxWidth: "100vw",
                                width: "100%",
                            }}>
                            <Box sx={{ display: "flex", flex: 1, width: "100%" }}>
                                <TextField
                                    label="Data"
                                    type="date"
                                    variant="outlined"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                    sx={{ width: "100%" }}
                                />
                                {console.log("date", date)}
                            </Box>
                            <Box sx={{ display: "flex", flex: 1, width: "100%" }}>
                                <TextField
                                    label="Horário"
                                    type="time"
                                    variant="outlined"
                                    value={start}
                                    onChange={(e) => setStart(e.target.value)}
                                    disabled={allDay}
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                            <Box sx={{ display: "flex", width: "100%" }}>
                                <FormControlLabel
                                    control={
                                        <Select
                                            label="Duração"
                                            value={duration}
                                            onChange={(e) => setDuration(e.target.value)}
                                            sx={{ width: "100%", fontSize: "0.9rem" }}
                                            disabled={allDay}
                                        >
                                            <option value={1}>1 min</option>
                                            <option value={5}>5 min</option>
                                            <option value={10}>10 min</option>
                                            <option value={15}>15 min</option>
                                            <option value={20}>20 min</option>
                                            <option value={25}>25 min</option>
                                            <option value={30}>30 min</option>
                                            <option value={35}>35 min</option>
                                            <option value={40}>40 min</option>
                                            <option value={45}>45 min</option>
                                            <option value={50}>50 min</option>
                                            <option value={60}>1 h</option>
                                            <option value={90}>1 h e 30 min</option>
                                            <option value={120}>2 h</option>
                                        </Select>
                                    }
                                    label="Duração"
                                    labelPlacement="top"
                                    sx={{ fontSize: "0.9rem" }}
                                />
                            </Box>

                            <ClickAwayListener onClickAway={() => setIntervalClicked(false)}>
                                <div style={{ position: "relative" }}>
                                    <Tooltip title="Selecione a agenda, data e clique para ver os horários disponíveis" arrow>
                                        <Button
                                            onClick={() => setIntervalClicked((prev) => !prev)}
                                            color="primary"
                                            size="small"
                                            disabled={!selectedCalendarId || !date}
                                        >
                                            Horários Disponíveis
                                        </Button>
                                    </Tooltip>
                                    {intervalClicked ? (
                                        <Paper style={{
                                            position: "absolute",
                                            zIndex: 1,
                                            backgroundColor: "#ededed",
                                            border: "1px solid #ccc",
                                            borderRadius: "5px",
                                            padding: "5px",
                                            width: "140px",
                                            maxHeight: "180px",
                                            overflowY: "auto",
                                            top: "100%",
                                            right: "0",
                                            boxShadow: "0 0 5px rgba(0,0,0,0.5)"
                                        }}>
                                            <IntervalsDateList
                                                date={date}
                                                agendaId={selectedCalendarId.id}
                                                open={intervalClicked}
                                            />

                                        </Paper>) : null}
                                </div>
                            </ClickAwayListener>

                        </Stack>

                        <div>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setOpenRepeatEvent((prev) => !prev)}
                            >
                                Agendamento Recorrente
                                {openRepeatEvent
                                    ? <ChevronUp style={{ marginLeft: "5px" }} />
                                    : <ChevronDown style={{ marginLeft: "5px" }} />}
                            </Button>
                        </div>
                        <div style={{ marginTop: 6 }}>
                            <Collapse in={openRepeatEvent}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={repeatEvent}
                                            onChange={(e) => setRepeatEvent(e.target.checked)}
                                            name="repeatEvent"
                                            color="primary"
                                        />
                                    }
                                    label="Ativar agendamento recorrente"
                                />
                                <Divider />
                                <Grid container spacing={2}>
                                    {daysOfWeek.map((day, index) => (
                                        <Grid item xs={6} key={index}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={selectedDays.includes(index)}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setSelectedDays((prev) => [...prev, index]);
                                                                console.log("selectedDays", selectedDays);
                                                            } else {
                                                                setSelectedDays((prev) => prev.filter((item) => item !== index));
                                                            }
                                                        }}
                                                        name={day}
                                                        color="primary"
                                                        disabled={!repeatEvent}
                                                    />
                                                }
                                                label={day}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                <div style={{ marginTop: 10 }}>
                                    <Typography variant="caption" style={{ fontSize: "1rem" }}>
                                        Termina após{" "}
                                        <TextField
                                            type="number"
                                            variant="outlined"
                                            value={ocurrences}
                                            onChange={(e) => setOcurrences(e.target.value)}
                                            disabled={!repeatEvent}
                                            inputProps={{ style: { textAlign: 'center', padding: '0' } }} // Centraliza o texto no input e remove o padding interno
                                            style={{ width: "5ch", marginLeft: "5px", marginRight: "5px", marginBottom: "-6px" }}
                                        />
                                        {" "}ocorrências
                                    </Typography>
                                </div>
                            </Collapse>
                        </div>

                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Salvar
                    </Button>
                </DialogActions>

            </Dialog>
        </div>
    );
};

export default BlockModal;