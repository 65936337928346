import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";

import {
    makeStyles,
    Button,
    TextField,
    Typography,
    Paper,
    Grid,
    InputAdornment,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import SearchIcon from "@material-ui/icons/Search";


import Container from "@material-ui/core/Container"
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";

import api from "../../services/api";
import { set } from "date-fns";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import ConfirmationModal from "../../components/ConfirmationModal";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    textField: {
        marginRight: theme.spacing(1),
        width: "100%",
    },
    paperContainer: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        gap: theme.spacing(2),
    },
}));


const JudUser = () => {
    const classes = useStyles();

    const initialState = {
        number: "",
        name: "",
        quantidade: 0,
    };

    const [judUser, setJudUser] = useState(initialState);
    const [searchParam, setSearchParam] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [newQuantidade, setNewQuantidade] = useState(0);
    const [selectedJudUser, setSelectedJudUser] = useState(null);

    const { user } = useContext(AuthContext);


    const handleSaveJudUser = async (values) => {

        const judUserData = { ...values, user: user.id };

        console.log(judUserData);

        try {
            const  judUser = await api.post("/judusers", judUserData);
            setSearchParam(judUser.name);
            handleSearch();            
            toast.success("Usuário cadastrado com sucesso!");
        } catch (error) {
            toastError(error);
        }

        setJudUser(initialState);
        setSearchParam("");
    };

    const handleSearch = async () => {
        try {
            const { data } = await api.get("/judusers", {
                params: { searchParam },
            });
            console.log(data);
            setSearchResult(data);
            setSearchParam("");
        } catch (error) {
            toastError(error);
        }
    }

    const handleUpdateJudUser = async (judUserId, newQuantidade) => {
        console.log(judUserId, newQuantidade);
        try {
            const judUpdate = await api.put(`/judusers/${judUserId}`, { quantidade: newQuantidade });
            setSearchResult(searchResult.map(judUser => judUser.id === judUserId ? { ...judUser, quantidade: newQuantidade } : judUser));
            setEditMode(false);
            setNewQuantidade(0);
            console.log(judUpdate);
        } catch (error) {
            toastError(error);
        }
    }

    const handleEditJudUser = (judUser) => {
        setNewQuantidade(judUser.quantidade);
        setSelectedJudUser(judUser);
        setEditMode(true);
    }

    return (
        <Container maxWidth="lg" className={classes.container}>           
            <MainHeader>
                <Title>Usuários do SSA Jud</Title>
            </MainHeader>

            <div>
                <Typography variant="h6">Cadastro do Usuário</Typography>
                <Paper className={classes.paperContainer}>
                    <Formik
                        initialValues={judUser}
                        enableReinitialize={true}
                        onSubmit={(values, actions) => {
                            setTimeout(() => {
                                handleSaveJudUser(values);
                                actions.resetForm();
                                actions.setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({ values, errors, touched, isSubmitting }) => (
                            <Form>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={4}>
                                        <Field
                                            as={TextField}
                                            label="Nome"
                                            name="name"
                                            variant="outlined"
                                            className={classes.textField}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field
                                            as={TextField}
                                            label="Número"
                                            name="number"
                                            variant="outlined"
                                            className={classes.textField}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={4} >
                                        <Field
                                            as={TextField}
                                            label="Quantidade de consultas liberadas"
                                            name="quantidade"
                                            variant="outlined"
                                            className={classes.textField}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <div style={{ marginTop: 8, textAlign: "right" }}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={isSubmitting}
                                        variant="contained"
                                    >Salvar</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Paper>
            </div>

            <div>
                <div style={{ display: "flex", flexDirection: "row", margin: "15px 0" }}>
                    <Typography variant="h6">Buscar Usuários SSA Jud</Typography>
                    <TextField
                        placeholder="Buscar Usuário"
                        type="search"
                        value={searchParam}
                        onChange={(e) => setSearchParam(e.target.value)}
                        style={{ marginLeft: "auto" }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ color: "gray" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearch}
                        style={{ marginLeft: 8, marginRight: 16 }}
                    >Buscar</Button>
                </div>
                <Paper className={classes.paperContainer}>
                    <Typography variant="h6">Resultado da Busca</Typography>
                    <div style={{ marginTop: "15px" }}>
                        <>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">ID</TableCell>
                                        <TableCell align="center">Nome</TableCell>
                                        <TableCell align="center">Número</TableCell>
                                        <TableCell align="center">Quantidade de consultas</TableCell>
                                        <TableCell align="center">Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {searchResult.length > 0 ? searchResult.map((judUser) => (
                                        <TableRow key={judUser.id}>
                                            <TableCell align="center">{judUser.id}</TableCell>
                                            <TableCell align="center">{judUser.name}</TableCell>
                                            <TableCell align="center">{judUser.number}</TableCell>
                                            <TableCell align="center">
                                                {editMode && judUser.id === selectedJudUser.id ? (
                                                    <TextField
                                                        type="number"
                                                        value={newQuantidade}
                                                        onChange={(e) => {
                                                            setNewQuantidade(e.target.value);
                                                        }}
                                                    />
                                                ) : (
                                                    judUser.quantidade
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                {editMode && judUser.id === selectedJudUser.id ? (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleUpdateJudUser(judUser.id, newQuantidade)}
                                                    >Salvar</Button>
                                                ) : (
                                                    <>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => handleEditJudUser(judUser)}
                                                        >Editar</Button>                                                       
                                                    </>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    )) : (
                                        <TableRow>
                                            <TableCell colSpan={5} align="center">Nenhum resultado encontrado</TableCell>
                                        </TableRow>
                                    )}

                                </TableBody>
                            </Table>
                        </>
                    </div>
                </Paper>
            </div>

        </Container>

    );
};

export default JudUser;