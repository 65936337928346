import React, { useReducer, useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Chip,
    Box,
    List,
    ListItem,
    ListItemText,
    IconButton,
    FormControlLabel,
    Switch,
    Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import toastError from "../../errors/toastError";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Stack } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';
import { addDays, max, set, setDate } from 'date-fns';
import { DateTimePicker } from '@mui/x-date-pickers';
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from "react-toastify";
import isArray from "lodash/isArray";
import { tr } from "date-fns/locale";




const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: "wrap",
        padding: theme.spacing(2),
    },
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_CONTACTS") {
        const contacts = action.payload;
        const newContacts = [];

        contacts.forEach((contact) => {
            const contactIndex = state.findIndex((c) => c.id === contact.id);
            if (contactIndex !== -1) {
                state[contactIndex] = contact;
            } else {
                newContacts.push(contact);
            }
        });
        return [...state, ...newContacts];
    }

    if (action.type === "UPDATE_CONTACTS") {
        const contact = action.payload;
        const contactIndex = state.findIndex((c) => c.id === contact.id);

        if (contactIndex !== -1) {
            state[contactIndex] = contact;
            return [...state];
        } else {
            return [contact, ...state];
        }
    }

    if (action.type === "DELETE_CONTACT") {
        const contactId = action.payload;

        const contactIndex = state.findIndex((c) => c.id === contactId);
        if (contactIndex !== -1) {
            state.splice(contactIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const EventsModalEdit = ({ open, onClose, event, agendaName, tab, onAuthorized }) => {
    const classes = useStyles();

    const [contacts, dispatch] = useReducer(reducer, []);
    const [calendars, setCalendars] = useState([]);
    const [routines, setRoutines] = useState([]);

    const [title, setTitle] = useState("");
    const [start, setStart] = useState(null);
    const [description, setDescription] = useState("");
    const [selectedContact, setSelectedContact] = useState(null);
    const [selectedCalendarId, setSelectedCalendarId] = useState(null);
    const [selectedRoutines, setSelectedRoutines] = useState([]);
    const [events, setEvents] = useState([]);

    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    const [allDay, setAllDay] = useState(false);
    const [duration, setDuration] = useState(0);
    const [date, setDate] = useState(null);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("/contacts/list", {
                        params: { searchParam, pageNumber },
                    });
                    dispatch({ type: "LOAD_CONTACTS", payload: data });

                    if (event.contactId) {
                        setSelectedContact(data.find(contact => contact.id === event.contactId));
                    }

                } catch (err) {
                    console.log('err', err);
                    toastError(err);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [open, event, searchParam, pageNumber]);

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const { data } = await api.get("/agendas");
                setCalendars(data);

                if (event.agendaId) {
                    setSelectedCalendarId(data.find(calendar => calendar.id === event.agendaId));
                }

                console.log(agendaName);

                const newDate = new Date(event.date);
                setDate(newDate.toISOString().split("T")[0]);

                setStart(event.start);
                setTitle(event.title);
                setDescription(event.description);
                setDuration(event.duration);
                setAllDay(event.allDay);

            } catch (error) {
                console.log(error);
                toastError(error);
            }
        })();
        fetchRoutines();
        setLoading(false);
    }, []);

    const fetchRoutines = async () => {
        try {
            const { data } = await api.get("/agendas/routine");
            setRoutines(data);

            console.log("event ", event);

            if (event.routineId) {
                setSelectedRoutines(data.find(routine => routine.id === event.routineId));
            }
            console.log(data);
        } catch (error) {
            console.log(error);
            toastError(error);
        }
    };

    const renderOptionLabel = (option) => {
        // Verifique se o valor de option não está indefinido ou nulo
        if (!option || typeof option === 'string') {
            return option || '';  // Retorna uma string vazia caso não haja opção
        }
        // Verifique se o contato tem o nome definido
        return option.name ? `${option.name} - ${option.number}` : 'Sem nome';
    };

    const renderAgendaLabel = (option) => {
        // Verifique se o valor de option não está indefinido ou nulo
        if (!option || typeof option === 'string') {
            return option || '';  // Retorna uma string vazia caso não haja opção
        }
        // Verifique se a agenda tem o nome definido
        return option.title ? `${option.title}` : 'Sem nome';
    };

    const handleSelectOption = (e, newValue) => {
        setSelectedContact(newValue);
    };

    const handleSelectAgenda = (e, newValue) => {
        setSelectedCalendarId(newValue);
    };

    const handleRoutineChange = (e, newValue) => {
        setSelectedRoutines(newValue);
    };

    const handleRemoveRoutine = (id) => {
        setSelectedRoutines(selectedRoutines.filter(routine => routine.id !== id));
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        console.log('scrolling');
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleSubmit = async () => {

        const contactId = selectedContact ? selectedContact.id : null;
        const routineId = selectedRoutines ? selectedRoutines.id : null;

        const eventData = {
            title,
            start: allDay ? "00:00" : start,
            date,
            allDay,
            duration,
            description,
            contactId,
            routineId,
        };

        console.log("eventUpdate ", eventData);

        try {

            if (event.blocked) {

                await api.put(`/events/blocked/${event.id}`, {
                    title,
                    date,
                    allDay,
                    start: allDay ? "00:00" : start,
                    duration,
                    description,
                    blocked: true,
                });

                toast.success("Bloqueio atualizado com sucesso!");
                
            } else {
                await api.put(`/events/${event.id}`, eventData);
                toast.success("Evento atualizado com sucesso!");

            }
            //se tab for selfSchedule envia para o componente pai que foi autorizado
            if (tab === "selfSchedule") {
                onAuthorized(event.id);
            }
        } catch (error) {
            console.error("Erro ao atualizar o evento:", error);
            toastError(error);
        }
        onClose();
        setTitle("");
        setStart(null);
        setDate(null);
        setAllDay(false);
        setDuration(0);
        setDescription("");
        setSelectedContact(null);
        setSelectedCalendarId(null);
        setSelectedRoutines([]);
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={onClose}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title">{event.blocked ? `Edita Bloqueio da Agenda: ${agendaName}` : `Editar Evento da Agenda: ${agendaName}`}</DialogTitle>
                {!loading && (
                    <DialogContent dividers>
                        <TextField
                            fullWidth
                            label="Evento"
                            margin="dense"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />

                        {!event.blocked && (
                            <Stack direction={'row'} alignItems={'center'}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    maxWidth: "100vw",
                                    width: "100%",
                                    marginTop: 2,
                                }}
                            >
                                <Autocomplete
                                    options={contacts}
                                    limitTags={1}
                                    style={{ width: "100%" }}
                                    value={selectedContact}
                                    id="multiple-limit-tags"
                                    getOptionLabel={renderOptionLabel}
                                    onChange={(e, newValue) => handleSelectOption(e, newValue)}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            variant="outlined"
                                            sx={{ width: '100%' }}
                                            placeholder="Escolha o contato"
                                        />
                                    )}
                                />
                            </Stack>
                        )}
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={allDay}
                                    onChange={(e) => setAllDay(e.target.checked)}
                                    name="allDay"
                                    color="primary"
                                />
                            }
                            label="Dia inteiro"
                        />
                        <Stack spacing={2} direction={'row'}
                            sx={{
                                my: 2,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                maxWidth: "100vw",
                                flex: "auto",
                                width: "100%",
                            }}>
                            <Box sx={{ display: "flex", flex: 1, width: "100%" }}>
                                <TextField
                                    label="Data"
                                    type="date"
                                    variant="outlined"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                            <Box sx={{ display: "flex", flex: 1, width: "100%" }}>
                                <TextField
                                    label="Horário"
                                    type="time"
                                    variant="outlined"
                                    value={start}
                                    disabled={allDay}
                                    onChange={(e) => setStart(e.target.value)}
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                            <Box sx={{ display: "flex", flex: 1, width: "100%" }}>
                                <FormControlLabel
                                    control={
                                        <Select
                                            label="Duração"
                                            value={duration}
                                            disabled={allDay}
                                            onChange={(e) => setDuration(e.target.value)}
                                            sx={{ width: "100%" }}
                                        >
                                            <option value={5}>5 min</option>
                                            <option value={10}>10 min</option>
                                            <option value={15}>15 min</option>
                                            <option value={20}>20 min</option>
                                            <option value={25}>25 min</option>
                                            <option value={30}>30 min</option>
                                            <option value={35}>35 min</option>
                                            <option value={40}>40 min</option>
                                            <option value={45}>45 min</option>
                                            <option value={50}>50 min</option>
                                            <option value={60}>1 h</option>
                                            <option value={90}>1 h e 30 min</option>
                                            <option value={120}>2 h</option>
                                        </Select>
                                    }
                                    label="Duração"
                                />
                            </Box>
                        </Stack>
                        <div>
                            <TextField
                                label="Descrição"
                                variant="outlined"
                                fullWidth
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                multiline
                                rows={2}
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            {/*
                            <List>
                                {selectedRoutines.map(routine => (
                                    <ListItem
                                        key={routine.id}
                                        secondaryAction={
                                            <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveRoutine(routine.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemText
                                            primary={routine.title}
                                            secondary={`será enviada ${routine.timeBefore} ${routine.timeUnit}`}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                            */}

                            {!event.blocked && (
                                <Autocomplete
                                    style={{ marginTop: 3 }}
                                    options={routines} // Lista de rotinas disponível
                                    filterSelectedOptions // Filtra as rotinas já selecionadas
                                    getOptionLabel={renderAgendaLabel}
                                    value={selectedRoutines} // Rotinas atualmente selecionadas
                                    onChange={handleRoutineChange}
                                    renderTags={(selected, getTagProps) =>
                                        selected.map((option, index) => (
                                            <Chip
                                                variant="outlined"
                                                key={option.id} // Identificador único da rotina
                                                label={option.title} // Exibe o nome da rotina
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Selecione a Rotina"
                                            placeholder="Escolher rotina"
                                        />
                                    )}
                                />
                            )}
                        </div>

                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={onClose} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        {tab === "selfSchedule" ? "Autorizar" : "Salvar"}
                    </Button>
                </DialogActions>

            </Dialog>
        </div>
    );
};

export default EventsModalEdit;