import React, { useState } from "react";
import moment from "moment";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/pt-br"; // Importa o locale de português brasileiro

import MainContainer from "../../components/MainContainer";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { Button, Typography } from "@material-ui/core";
import AgendaModal from "../../components/AgendaModal";
import AgendaRoutineModal from "../../components/AgendaRoutineModal";
import AgendasList from "../../components/AgendasList";
import AgendaRoutineList from "../../components/AgendaRoutineList";
import EventsModal from "../../components/EventsModal";
import { Container } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        overflow: "auto",
        ...theme.scrollbarStyles,
    },
    textField: {
        marginRight: theme.spacing(1),
        width: "100%",
    },
    mainPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "none",
        flexDirection: "column",
        flex: 1,
        maxWidth: "100vw"
        //height: 240,
    },
    buttonContainer: {
        flex: 1, 
        margin: 3
    },
    "@global": {
        ".rbc-calendar": {
            fontFamily: "'Nunito', sans-serif !important", // Estilo global
        },
        ".rbc-header": {
            fontWeight: "600 !important",
        },
        ".rbc-event": {
            fontWeight: "400 !important",
        },
        ".rbc-day-slot": {
            fontFamily: "'Nunito', sans-serif !important",
        },
    },
}));

const CalendarSettings = () => {
    const classes = useStyles();

    const [agendaModalOpen, setAgendaModalOpen] = useState(false);
    const [routineModalOpen, setRoutineModalOpen] = useState(false);
    const [eventModalOpen, setEventModalOpen] = useState(false);

    const handleOpenAgendaModal = () => {
        setAgendaModalOpen(true);
    };

    const handleCloseAgendaModal = () => {
        setAgendaModalOpen(false);
    };

    const handleOpenRoutineModal = () => {
        setRoutineModalOpen(true);
    };

    const handleCloseRoutineModal = () => {
        setRoutineModalOpen(false);
    };

    const handleCloseEventModal = () => {
        setEventModalOpen(false);
    };

    const handleOpenEventModal = () => {
        setEventModalOpen(true);
    };

    return (
        <Container maxWidth="lg" className={classes.container}>
            <AgendaModal open={agendaModalOpen} handleClose={handleCloseAgendaModal} />
            <AgendaRoutineModal open={routineModalOpen} handleClose={handleCloseRoutineModal} />
            <EventsModal open={eventModalOpen} onClose={handleCloseEventModal} />
            <MainHeader>
                <Title>Calendário para Agendamentos</Title>
            </MainHeader>
            <div style={{ justifyContent: "space-between", display: "flex", marginBottom: 16 }}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonContainer}
                    onClick={handleOpenAgendaModal}
                >
                    Criar Agenda
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonContainer}
                    onClick={handleOpenRoutineModal}
                >
                    Criar Rotina de Mensagens
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonContainer}
                    onClick={handleOpenEventModal}
                >
                    Criar Evento
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonContainer}
                    href="/EventSearch"
                >
                    Procurar Evento
                </Button>
            </div>

            <Paper className={classes.mainPaper} variant="outlined">
                <Typography variant="h6">Agendas</Typography>
                <AgendasList />
            </Paper>

            <Paper className={classes.mainPaper} style={{ marginTop: 10}} variant="outlined">
                <Typography variant="h6">Rotina de Mensagens</Typography>
                <AgendaRoutineList />
            </Paper>

        </Container>
    );
};

export default CalendarSettings;