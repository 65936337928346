import React, { useCallback, useState, useReducer, useEffect } from 'react';
import {
    Container,
    Box,
    TextField,
    Paper,
    InputAdornment,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Tooltip,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';
import { Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import MainHeader from '../MainHeader';
import Title from '../Title';
import MainHeaderButtonsWrapper from '../MainHeaderButtonsWrapper';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO, set } from 'date-fns';
import brLocale from 'date-fns/locale/pt-BR';
import SearchIcon from '@material-ui/icons/Search';
import TableRowSkeleton from '../TableRowSkeleton';
import api from '../../services/api';
import EditIcon from '@material-ui/icons/Edit';
import MainContainer from '../MainContainer';
import Event from '../Event';


const reducer = (state, action) => {
    if (action.type === "LOAD_EVENTS") {
        const events = action.payload;
        const newEvents = [];

        events.forEach((event) => {
            const eventIndex = state.findIndex((s) => s.id === event.id);
            if (eventIndex !== -1) {
                state[eventIndex] = event;
            } else {
                newEvents.push(event);
            }
        });

        return [...state, ...newEvents];
    }

    if (action.type === "UPDATE_EVENTS") {
        const event = action.payload;
        const eventIndex = state.findIndex((s) => s.id === event.id);

        if (eventIndex !== -1) {
            state[eventIndex] = event;
            return [...state];
        } else {
            return [event, ...state];
        }
    }

    if (action.type === "RESET_EVENTS") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        overflow: "none",
    },
}));

const EventSearch = ({ contactId }) => {
    const classes = useStyles();

    const [searchParam, setSearchParam] = useState('');
    const [initialDate, setInitialDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);

    const [selectedEvent, setSelectedEvent] = useState(null);


    const [events, dispatch] = useReducer(reducer, []);

    const fetchEvents = async () => {
        setLoading(true);
        try {
            const response = await api.get('/events', {
                params: {
                    searchParam,
                    initialDate,
                    finalDate,
                    contactId,
                }
            });
            response.data.forEach((event) => {
                event.date = format(parseISO(event.date), 'dd/MM/yyyy');
            });

            response.data.sort((a, b) => {
                return new Date(a.date) - new Date(b.date);
            });

            console.log(response.data);
            if (contactId) {
                //filtrar os eventos do contato
                const contactEvents = response.data.filter((event) => event.contact.id === contactId);
                dispatch({ type: 'LOAD_EVENTS', payload: contactEvents });
            } else {
                dispatch({ type: 'LOAD_EVENTS', payload: response.data });
            }

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        dispatch({ type: 'RESET_EVENTS' });
        setPageNumber(1);
        fetchEvents();
        setLoading(false);
        console.log("event", events);
    }, [searchParam, initialDate, finalDate, pageNumber]);

    /*useEffect(() => {
        fetchEvents();
        setLoading(false);
    }, [searchParam, initialDate, finalDate, pageNumber]);*/


    const handleSearch = (event) => {
        setSearchParam(event.target.value);
    }


    return (
        <MainContainer>
            {selectedEvent && (
                <Event
                    eventId={selectedEvent.id}
                    open={selectedEvent !== null}
                    onClose={() => setSelectedEvent(null)}
                />
            )}
            {!contactId && (
                <>
                    <MainHeader>
                        <Title>Eventos</Title>
                        <MainHeaderButtonsWrapper>
                            <Stack direction={{ xs: "column", sm: "row" }}>
                                <TextField
                                    placeholder="Buscar evento"
                                    type="search"
                                    size='small'
                                    fullWidth
                                    variant='outlined'
                                    value={searchParam}
                                    onChange={handleSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{ color: "gray" }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flex: 1 }}
                                />
                                <Box style={{ padding: '5px', height: '66px', alignItems: 'center', display: 'flex', justifyContent: 'center', flex: 1 }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                                        <DatePicker
                                            value={initialDate}
                                            onChange={(newValue) => { setInitialDate(newValue) }}
                                            label="Data inicial"
                                            renderInput={(params) => <TextField variant="outlined" size="small" fullWidth {...params} sx={{ width: '20ch', height: '100%' }} />}

                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box style={{ padding: '5px', height: '66px', alignItems: 'center', display: 'flex', justifyContent: 'center', flex: 1 }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                                        <DatePicker
                                            value={finalDate}
                                            onChange={(newValue) => { setFinalDate(newValue) }}
                                            label="Data final"
                                            renderInput={(params) => <TextField
                                                variant="outlined"
                                                size="small"
                                                fullWidth {...params} sx={{ width: '20ch', height: '100%' }} />}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Stack>
                        </MainHeaderButtonsWrapper>
                    </MainHeader>

                    <Paper className={classes.mainPaper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Id</TableCell>
                                    <TableCell align="center">Nome</TableCell>
                                    <TableCell align="center">Data</TableCell>
                                    <TableCell align="center">Horário</TableCell>
                                    <TableCell align="center">Agenda</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <>
                                    {events.map((event) => (
                                        <TableRow
                                            key={event.id}
                                            onClick={() => setSelectedEvent(event)}
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <TableCell align="center">{event.id}</TableCell>
                                            <TableCell align="center">
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    <Tooltip title={`Status: ${event.status}`} arrow placement='left'>
                                                        <div
                                                            style={{
                                                                backgroundColor: event.color,
                                                                marginRight: '10px',
                                                                borderRadius: 100,
                                                                width: '20px',
                                                                height: '20px',
                                                                flex: "none",
                                                            }}>
                                                        </div>
                                                    </Tooltip>
                                                    {event.blocked ? "Horário Bloqueado" : event.contact.name}
                                                </div>
                                            </TableCell>
                                            <TableCell align="center">{event.date}</TableCell>
                                            <TableCell align="center">{event.start}</TableCell>
                                            <TableCell align="center">{event.agenda.name}</TableCell>
                                        </TableRow>
                                    ))}
                                    {loading && (<TableRowSkeleton columns={6} />)}
                                </>
                            </TableBody>
                        </Table>
                    </Paper>
                </>
            )}

            {contactId && (
                <List>
                    {events.map((event) => (
                        <ListItem
                            key={event.id}
                            button
                            onClick={() => setSelectedEvent(event)}
                            style={{
                                borderLeft: `5px solid ${event.color}`,
                                borderBottom: '1px solid gray',
                            }}
                        >
                            <ListItemText
                                primary={`${event.date} às ${event.start} - ${event.status}`}
                                secondary={event.agenda.name} />
                        </ListItem>
                    ))}
                </List>
            )}
        </MainContainer>
    );
}

export default EventSearch;