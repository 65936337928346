import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
    Chip,    
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useState, useEffect, useContext } from 'react';
import useWhatsApps from '../../hooks/useWhatsApps';
import { AuthContext } from '../../context/Auth/AuthContext';
import { Can } from "../Can";

import api from '../../services/api';

import { i18n } from "../../translate/i18n";
import { toast } from 'react-toastify';
import { set } from 'date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
}));

const daysOfWeek = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];

const monthsOfYear = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
];

const AgendaSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
});

const AgendaModalEdit = ({ open, handleClose, agendaId }) => {
    const classes = useStyles();

    const initialValues = {
        name: '',
        startTime: '',
        endTime: '',
        multipleEvents: false,
        selfScheduleTime: 0,
    };

    const { user: loggedInUser } = useContext(AuthContext);

    const { loading, whatsApps } = useWhatsApps();
    const [whatsappId, setWhatsappId] = useState(false);
    const [daysSelected, setDaysSelected] = useState([]);
    const [selfSchedule, setSelfSchedule] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState([]);

    const [agenda, setAgenda] = useState(initialValues);
    const [pageLoading, setPageLoading] = useState(false);

    const [routines, setRoutines] = useState([]);
    const [selectedRoutine, setSelectedRoutine] = useState(null);

    useEffect(() => {
        setPageLoading(true);
        if (agendaId) {
            const fetchAgenda = async () => {
                try {
                    const response = await api.get(`/agendas/${agendaId}`);
                    const routines = await api.get("/agendas/routine");

                    setRoutines(routines.data);

                    const {
                        name,
                        monday,
                        tuesday,
                        wednesday,
                        thursday,
                        friday,
                        saturday,
                        sunday,
                        startTime,
                        endTime,
                        multipleEvents,
                        whatsappId,
                        selfSchedule,
                        selfScheduleTime,
                        monthsOfYear,
                        routineId,
                    } = response.data;

                    setWhatsappId(whatsappId);
                    setSelfSchedule(selfSchedule);

                    if (routineId) {
                        const selectedRoutine = routines.data.find(routine => routine.id === routineId);
                        setSelectedRoutine(selectedRoutine);
                    }

                    //monthsOfYear é uma string com os índices dos meses separados por vírgula
                    if (monthsOfYear) {
                        const parsedSelectedMonth = monthsOfYear
                            .replace(/[\[\]\s]/g, '') // Remove colchetes e espaços
                            .split(',')                // Separa os valores por vírgula
                            .map(Number);              // Converte cada elemento para um número
                        console.log(parsedSelectedMonth);
                        setSelectedMonth(parsedSelectedMonth);
                    }

                    const days = [
                        monday && 'Segunda',
                        tuesday && 'Terça',
                        wednesday && 'Quarta',
                        thursday && 'Quinta',
                        friday && 'Sexta',
                        saturday && 'Sábado',
                        sunday && 'Domingo',
                    ].filter(Boolean);

                    setDaysSelected(days);
                    console.log(days);


                    const agendaData = {
                        name,
                        startTime,
                        endTime,
                        multipleEvents,
                        selfScheduleTime: selfScheduleTime || 0,
                    };

                    console.log(agendaData);

                    setAgenda(prevState => ({ ...prevState, ...agendaData }));

                } catch (error) {
                    toast.error('Erro ao buscar agenda!');
                }
            };

            fetchAgenda();
            setPageLoading(false);
        }
    }, [agendaId]);


    const handleSubmit = async (values) => {

        const routineId = selectedRoutine ? selectedRoutine.id : null;

        const agendaData = {
            ...values,
            selectedDays: daysSelected,
            whatsappId,
            selfSchedule,
            monthsOfYear: selectedMonth,
            routineId,
        };

        console.log(agendaData);

        try {
            const response = await api.put(`/agendas/${agendaId}`, agendaData);

            toast.success('Agenda atualizada com sucesso!');
        } catch (error) {
            toast.error('Erro ao atualizar agenda!');
        }

        handleClose();
    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Editar Agenda</DialogTitle>
            {!pageLoading && (
                <DialogContent dividers>
                    <Formik
                        initialValues={agenda}
                        enableReinitialize={true}
                        validationSchema={AgendaSchema}
                        onSubmit={(values, actions) => {
                            setTimeout(() => {
                                handleSubmit(values);
                                actions.setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({ values, isSubmitting, errors, touched, setFieldValue }) => (
                            <Form>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        name="name"
                                        as={TextField}
                                        label="Nome da agenda"
                                        fullWidth
                                        margin="dense"
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                    />

                                    <Can
                                        role={loggedInUser.profile}
                                        perform="user-modal:editQueues"
                                        yes={() => (!loading &&
                                            <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                                                <InputLabel>{i18n.t("userModal.form.whatsapp")}</InputLabel>
                                                <Field
                                                    as={Select}
                                                    value={whatsappId}
                                                    onChange={(e) => setWhatsappId(e.target.value)}
                                                    label={i18n.t("userModal.form.whatsapp")}
                                                >
                                                    <MenuItem value={''}>&nbsp;</MenuItem>
                                                    {whatsApps.map((whatsapp) => (
                                                        <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
                                                    ))}
                                                </Field>
                                            </FormControl>
                                        )}
                                    />
                                </div>
                                <Typography variant="h6" style={{ fontSize: "1rem", marginTop: 5 }}>
                                    Dias da Semana
                                </Typography>

                                <Grid container spacing={2}>
                                    {daysOfWeek.map((day) => (
                                        <Grid item xs={6} key={day}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={daysSelected.includes(day)}
                                                        onChange={() => {
                                                            const selected = daysSelected.includes(day) // se o dia já estiver selecionado, remove
                                                                ? daysSelected.filter((d) => d !== day) // remove
                                                                : [...daysSelected, day]; // adiciona
                                                            setDaysSelected(selected);
                                                        }}
                                                    />
                                                }
                                                label={day}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Field
                                            name="startTime"
                                            as={TextField}
                                            label="Horário de Início"
                                            type="time"
                                            value={values.startTime}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            error={touched.startTime && Boolean(errors.startTime)}
                                            helperText={touched.startTime && errors.startTime}
                                            onChange={(e) => {
                                                setFieldValue('startTime', e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name="endTime"
                                            as={TextField}
                                            label="Horário de Fim"
                                            type="time"
                                            value={values.endTime}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            error={touched.endTime && Boolean(errors.endTime)}
                                            helperText={touched.endTime && errors.endTime}
                                            onChange={(e) => {
                                                setFieldValue('endTime', e.target.value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <div style={{ marginTop: 4 }}>
                                    <FormControlLabel
                                        control={
                                            <Field
                                                name="multipleEvents"
                                                as={Checkbox}
                                                checked={values.multipleEvents}
                                                onChange={(e) => setFieldValue('multipleEvents', e.target.checked)}
                                            />
                                        }
                                        label="Permitir múltiplos eventos no mesmo horário"
                                    />
                                </div>

                                <div style={{ marginTop: 4 }}>
                                    <FormControlLabel
                                        control={
                                            <Field
                                                name="selfSchedule"
                                                as={Checkbox}
                                                checked={selfSchedule}
                                                onChange={(e) => setSelfSchedule(e.target.checked)}
                                            />
                                        }
                                        label="Permitir o auto agendamento"
                                    />
                                </div>
                                {selfSchedule && (
                                    <>
                                        <Typography variant="h6" style={{ fontSize: "1rem", marginTop: 5 }}>
                                            Duração dos horários para auto agendamento
                                        </Typography>
                                        <div style={{ width: "50%" }}>
                                            <Field
                                                name="selfScheduleTime"
                                                as={TextField}
                                                type="number"
                                                fullWidth
                                                value={values.selfScheduleTime}
                                                onChange={(e) => setFieldValue('selfScheduleTime', e.target.value)}
                                                helperText="Tempo em minutos"
                                            />
                                        </div>

                                        <div style={{ marginTop: "10px" }}>
                                            <Autocomplete
                                                multiple
                                                id="tags-outlined"
                                                options={monthsOfYear}
                                                getOptionLabel={(option) => option}
                                                filterSelectedOptions
                                                value={selectedMonth.map((index) => monthsOfYear[index])}
                                                onChange={(event, newValue) => {
                                                    // Mapear os nomes dos meses selecionados de volta para seus índices
                                                    const selectedMonthIndices = newValue.map(month => monthsOfYear.indexOf(month));
                                                    setSelectedMonth(selectedMonthIndices);
                                                }}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            variant="outlined"
                                                            key={index}
                                                            label={option}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Meses do ano que estará disponível para auto agendamento"
                                                        placeholder="Selecione os meses"
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                            <Autocomplete
                                                style={{ marginTop: 3 }}
                                                options={routines} // Lista de rotinas disponível
                                                filterSelectedOptions // Filtra as rotinas já selecionadas
                                                getOptionLabel={(option) => option.title || "Escolher rotina"} // Exibe o nome da rotina
                                                value={selectedRoutine} // Rotina selecionada
                                                onChange={(event, newValue) => {
                                                    setSelectedRoutine(newValue);
                                                }}
                                                renderTags={(selected, getTagProps) =>
                                                    selected.map((option, index) => (
                                                        <Chip
                                                            variant="outlined"
                                                            key={option.id} // Identificador único da rotina
                                                            label={option.title} // Exibe o nome da rotina
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Selecione a Rotina"
                                                        placeholder="Escolher rotina"
                                                    />
                                                )}
                                            />
                                        </div>
                                    </>
                                )}
                                <DialogActions>
                                    <Button onClick={handleClose} color="secondary" disabled={isSubmitting}>
                                        Cancelar
                                    </Button>
                                    <Button type="submit" color="primary" disabled={isSubmitting}>
                                        Atualizar
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default AgendaModalEdit;
